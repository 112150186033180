import React from "react"
import { Link } from "gatsby"
import { SwapRightOutlined, PhoneOutlined } from "@ant-design/icons"
import styled from "@emotion/styled"

const StyleWrapper = styled(Link)`
  min-width: 160px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  background-color: #fc1a58;
  border: 2px solid #fc1a58;
  border-radius: 25.5px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #221638;
    border: 2px solid #221638;
    text-decoration: none;
  }
  img {
    margin-left: 35px;
    margin-bottom: 0;
    max-height: 15px;
  }
`
const LinkWrapper = styled.a`
  min-width: 142px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  background-color: #00c300;
  border: 2px solid #00c300;
  border-radius: 6px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #221638;
    border: 2px solid #221638;
    text-decoration: none;
  }
  .anticon {
    font-size: 18px;
    margin-right: 5px;
  }
`
const LinkPhoneWrapper = styled.a`
  min-width: 142px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-family: "Prompt", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  background-color: #0699e2;
  border: 2px solid #0699e2;
  border-radius: 6px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #221638;
    border: 2px solid #221638;
    text-decoration: none;
  }
  .anticon {
    font-size: 18px;
    margin-right: 5px;
  }
`

const ButtonWrapper = ({ text, cta, path, icon }) => {
  if (path && path !== "undefined") {
    if (icon === "phone") {
      return (
        <LinkPhoneWrapper href={cta} target="_blank" rel="noreferrer">
          <PhoneOutlined />
          {text}
        </LinkPhoneWrapper>
      )
    } else {
      return (
        <LinkWrapper href={cta} target="_blank" rel="noreferrer">
          <SwapRightOutlined />
          {text}
        </LinkWrapper>
      )
    }
  }
  return (
    <StyleWrapper to={cta}>
      <SwapRightOutlined />
      {text}
    </StyleWrapper>
  )
}

export default ButtonWrapper
